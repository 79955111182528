import { notification } from 'antd';


const createNotification = (type, id, formatMessage, location) => {
  let description = '';
  let message = formatMessage({id: id});
  let placement = 'topRight';
  if (location) {
    placement = location; 
  } 
    
  notification[type]({
    message,
    description,
    placement
  });
};
export default createNotification;
