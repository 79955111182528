import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { multiFactor, TotpMultiFactorGenerator, getMultiFactorResolver, TotpSecret, getAuth, emailAuthProvider } from "firebase/auth";
import { QRCode } from 'react-qrcode-logo';
import { connect } from "react-redux";
import { MailOutlined, UnlockOutlined } from '@ant-design/icons';
import { Input, Divider, Avatar, Button  } from "antd";
import authAction from "../../redux/auth/actions";
import appAction from "../../redux/app/actions";
import { FormattedMessage, injectIntl } from 'react-intl';
import IntlMessages from "../../components/utility/intlMessages";
import FirebaseHelper from "../../helpers/firebase/index";
import notification from '../../components/uielements/notification';
import notificationIntl from '../../components/uielements/notificationIntl';
import dayjs from 'dayjs';
//import SocialMediaIcons from '../../settings/socialMedia/icons';
const { login , logout, addUserCredentials, changeUserAddress } = authAction;
const { clearMenu } = appAction; 
class SignIn extends Component {
  constructor(props){
      super(props);
      
      this.triggerSignup = this.triggerSignup.bind(this);
      this.triggerForgotPassword = this.triggerForgotPassword.bind(this);
      this.handleLogin = this.handleLogin.bind(this);
      this.myRef = React.createRef();
      this.state = {
        email:'',
        password:'',
        buttdispw: false,
        buttvalpw: false,
        buttdis: false,
        screen: 1,
        currentUser: '',
        totpUri: '',
        totpSecret: '',
        secret: '',
        recoveryPin: '',
        verificationCode: '',
        otpFromAuthenticator: '',
        error: ''
      }
  }
  state = {
    redirectToReferrer: false
  };

  

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.isLoggedIn !== nextProps.isLoggedIn &&
      nextProps.isLoggedIn === true
    ) {
      this.setState({ redirectToReferrer: true });
    }
  }

  componentDidMount() {
    const update = () => {
      this.myRef.current.focus();
    };
    setTimeout(update, 500);
  }

  handleLogin = (provider) => {
  const { formatMessage } = this.props.intl;
  const { email, password } = this.state;
  const { locale } = this.props;
  let userCred;

    if (provider == 'password') {
      

      if (!(email && password)) {
  //      notification('error', 'Please fill in email and password');
        notificationIntl('error','not.emailAndPassword',formatMessage);
        return;
      }
    }
    
    (async() => {
      const { socialMedia } = this.state;

      if (provider == 'password') {
        
        this.setState({ buttvalpw: true });
        this.setState({ buttdis: true });
       
        
        userCred = await FirebaseHelper.firebaseLogin(email,password,locale)

          .catch(error => {
            const message = error.message;
            const code = error.code;
           // console.log('error code', code )
            if (code === "auth/multi-factor-auth-required") {
              this.setState({ error: error});
              this.setState({ screen: 3});
              const update = () => {
                this.myRef.current.focus();
              };
              setTimeout(update, 500);
              
              return;
            } else {
            notification('error', message);
            this.props.logout();
            this.setState({ buttvalpw: false });
            this.setState({ buttdis: false });

            return;
          }

          })

          .then(result => {

           if (result) {
             
             if (result.user.emailVerified == false) {
               FirebaseHelper.sendEmailVerification(this.props.locale)
               .then(() => {
                  this.props.logout();
                  this.props.closeLogin();
                  notificationIntl('info','action.sendEmailVerification',formatMessage);
                  
                  this.setState({ buttvalpw: false });
                  this.setState({ buttdis: false });

                }).catch((error) => {
                  this.props.logout();
                  this.props.closeLogin();
                  notificationIntl('info','action.sendEmailVerification',formatMessage);
                 
                  this.setState({ buttvalpw: false });
                  this.setState({ buttdis: false });
                });

              return '';
            } else {
                     //   console.log('result',result);
              return  result;
                     
              }
           }
          });
     }

        if (userCred) {
              // second factor
              if (userCred.user.multiFactor.enrolledFactors.length === 0) {
                const multiFactorSession = await multiFactor(userCred.user).getSession();
                const totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
                const totpUri = totpSecret.generateQrCodeUrl( userCred.user.email, "PhraseLocker");
                const secret = totpSecret.secretKey;
                 
                this.setState({ currentUser: userCred.user });
                this.setState({ totpSecret: totpSecret });
                this.setState({ totpUri: totpUri });
                this.setState({ secret: secret });
                this.setState({ screen: 2 }); // give qr code (register factor)
                this.setState({ buttdis: false });
                const update = () => {
                  this.myRef.current.focus();
                };
                setTimeout(update, 500);
    
                return;
              } 
  
              } else {
                //notification('error', 'No user id found.');
                
                this.setState({ buttvalpw: false });
                this.setState({ buttdispw: false });
                this.setState({ buttdis: false });
                return;

              }
      })();
      //  });
    };

  triggerSignup() {
    this.props.handleSignup();
  }

  triggerForgotPassword() {
    this.props.handleForgotPassword();
  }
/*
  killSecondFactor() {
    const { email, password } = this.state;
    var cred = FirebaseHelper.auth.EmailAuthProvider.credential(email, password);
    console.log('cred',cred);
    var options = FirebaseHelper.enrolledFactors();
// Present user the option to unenroll.
    return FirebaseHelper.auth.currentUser.multiFactor.unenroll(options[0])
      .then(function() {
        // User successfully unenrolled selected factor.
        console.log('hat geklappt!!!!!!!!!!!!!');
      }).catch(function(error) {
        // Handler error.
        console.log('hat nicht geklappt!!!!!!!!!!!!! error: ',error);
      });
  }
  
  killShowScreen() {
     this.setState({ screen: 4 });
  } 
*/
  finalizeVerification(value) {
    const { formatMessage } = this.props.intl;
    const { verificationCode, totpSecret, currentUser, email, password } = this.state;
    const { locale } = this.props;

    this.setState({ verificationCode: value });
    let six = 6;
    if (value.length === six) {
      
      this.setState({ buttdis: true });
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
      totpSecret,
      value
    );
    //await 
    console.log('currentUser',currentUser);
    multiFactor(currentUser).enroll(multiFactorAssertion,"factor2")
    
    .then((result) => {
      notificationIntl('success','not.2ndFactorVerified',formatMessage);
      this.setState({ buttdis: false, otpFromAuthenticator: '', verificationCode: '' });
      this.props.logout();
      this.handleLogin('password');
      const update = () => {
        this.myRef.current.focus();
      };
      setTimeout(update, 500);
    })

  }
/*
        this.setState({ buttvalpw: true });
        this.setState({ buttdis: true });
       
        
        const userCred = await FirebaseHelper.firebaseLogin(email,password,locale)

          .catch(error => {
            const message = error.message;
            const code = error.code;
           // console.log('error code', code )
            if (code === "auth/multi-factor-auth-required") {
              this.setState({ error: error});
              this.setState({ screen: 3});
              const update = () => {
                this.myRef.current.focus();
              };
              setTimeout(update, 500);
              console.log('ok');
              return;
            } else {
            notification('error', message);
            this.props.logout();
            this.setState({ buttvalpw: false });
            this.setState({ buttdis: false });

            return;
            }
    

          })







      
    })
    .catch(error => {
      const message = error.message
      console.log('error message', message);
      notificationIntl('error','not.2ndFactorVerifiedFail',formatMessage);
      this.props.logout();
      this.props.closeLogin();
    });
    
    } else {
   //   notificationIntl('error','not.enterValid2FAcode',formatMessage);
    }this.setState({ buttvalpw: true });
        this.setState({ buttdis: true });
       
        
        userCred = await FirebaseHelper.firebaseLogin(email,password,locale)

          .catch(error => {
            const message = error.message;
            const code = error.code;
           // console.log('error code', code )
            if (code === "auth/multi-factor-auth-required") {
              this.setState({ error: error});
              this.setState({ screen: 3});
              const update = () => {
                this.myRef.current.focus();
              };
              setTimeout(update, 500);
              
              return;
            } else {
            notification('error', message);
            this.props.logout();
            this.setState({ buttvalpw: false });
            this.setState({ buttdis: false });

            return;*/
  }

  triggerReset2FA() {
 //   const { email } = this.state;
 //   console.log('currentUser',email,'jaada');
    this.setState({ screen: 4 });
  }

  setScreen3() {
    this.setState({ screen: 3 });
  }

  runReset2FA() {
    const { email, recoveryPin } = this.state;
    const { formatMessage } = this.props.intl;
    console.log(email, recoveryPin);
    if (recoveryPin.length > 3 && Number.isInteger(Number(recoveryPin))) {
      var date = dayjs();
      let data = {};
      data.date = date.format("YYYY-M-D");
      data.email = email;
      data.recoveryPin = recoveryPin;

      FirebaseHelper.firebaseInsertDocRand('reset2FA', data)
      .then(result => {
        notificationIntl('success','not.PINcheckBackend',formatMessage);
        this.setState({ recoveryPin: '' });
        this.props.closeLogin();
        this.props.logout();
       
        return;
      })
      .catch(error => {
        const message = error.message
        notification('error', message);
        this.setState({ recoveryPin: '' });
        this.props.closeLogin();
        this.props.logout();
        return;
      }); 
    } else {
      this.setState({ recoveryPin: '' });
      notificationIntl('error','not.useYourRecoveryPin',formatMessage);
      
    }
  }

  runVerification(value) {
    const { formatMessage } = this.props.intl;
    const { currentUser, otpFromAuthenticator, error } = this.state;
    this.setState({ otpFromAuthenticator: value });
    let six = 6;
      if (value.length === six) {
        this.setState({ buttdis: true });
        const mfaResolver = getMultiFactorResolver(getAuth(),error);
        const enrolledFactors = mfaResolver.hints.map(info => info.displayName);
        //console.log('enrolledFactors',enrolledFactors);
        //console.log('enrolledFactors',mfaResolver.hints);
        //console.log('mfaResolver.hints[0].uid',mfaResolver.hints[0].uid);
        
        const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
          mfaResolver.hints[0].uid,
          value
        );
        
          
       (async() => {  

          let userCredential = await mfaResolver.resolveSignIn(multiFactorAssertion)
          .then(userCred => { 
            if (userCred) {
              
              FirebaseHelper.firebaseGetDoc('members', userCred.user.uid)
                  .then(doc => {

                   
                if (doc.exists && doc.id) {
                  
                     let user = doc.data();
                     
                     if ( user.email !== userCred.user.email) {
                       FirebaseHelper.firebaseUpdateDocAttribute('members', userCred.user.uid, { email: userCred.user.email });
                       user.email = userCred.user.email;
                     }
                     let uid = doc.id;
               
                     if (user.accountId) { // is Affiliate
           
                      let payload = user.accountId;
                      FirebaseHelper.httpCallable('HttpCallableCheckStripeAccount',payload)
                      .then((result) => {
                        
                        this.props.changeUserAddress(user);
                        this.props.addUserCredentials('member');                  //0
                        this.props.addUserCredentials(user.username);             //1
                        this.props.addUserCredentials('member');                  //2
                        this.props.addUserCredentials(user.email);                //3
                        this.props.addUserCredentials('member');                  //4
                        this.props.addUserCredentials(uid);                       //5
                        this.props.addUserCredentials(result.data.payouts_enabled);    //6
                        this.props.addUserCredentials(result.data.link || '');         //7
                        this.props.login();
                        if (this.props.prodcount > 0){
                          this.props.history.push('/checkout');
                        }
                        return;
                      })
                      .catch((error) => {
                        console.log(error.code);
                        console.log(error.message);
                        return;
                      });
                    } else {           // is Member
                        
                      this.props.changeUserAddress(user);
                      this.props.addUserCredentials('member');         //0
                      this.props.addUserCredentials(user.username);    //1
                      this.props.addUserCredentials('member');         //2
                      this.props.addUserCredentials(user.email);       //3
                      this.props.addUserCredentials('member');         //4
                      this.props.addUserCredentials(uid);              //5
                      this.props.addUserCredentials('');               //6
                      this.props.addUserCredentials('');               //7
                      this.props.login();
                      if (this.props.prodcount > 0){
                        this.props.history.push('/checkout');
                      }
                      return;
          
                    }

                }
                else {
                    notificationIntl('error','not.notMember',formatMessage);
                    this.props.logout();
                    this.setState({ buttdisgoogle: false });
                    this.setState({ buttvalpw: false });
                    this.setState({ buttdis: false });

                }


                })
                .catch(err => {
                     console.log('catch 3',err);
                     notification('error', err.message);
                     this.setState({ buttdisgoogle: false });
                     this.setState({ buttvalpw: false });
                     this.setState({ buttvalgoogle: false });
                     this.setState({ buttdispw: false });
                     this.setState({ buttdis: false });
                     
                     return;
                   });

              } else {
                notification('error', 'The verification code is not valid.');
                this.setState({ buttdisgoogle: false });
                this.setState({ buttvalpw: false });
                this.setState({ buttvalgoogle: false });
                this.setState({ buttdispw: false });
                this.setState({ buttdis: false });
                
                return;

              }
      }
      
          ).catch(err => {
           // console.log('catch 4',err);
           // notification('error', err);
           
            notification('error', err.code);
            this.setState({ otpFromAuthenticator: '' });
            this.setState({ buttdisgoogle: false });
            this.setState({ buttvalpw: false });
            this.setState({ buttvalgoogle: false });
            this.setState({ buttdispw: false });
            this.setState({ buttdis: false });
           
            this.myRef.current.focus();
           
            return;
          });
        
        
      })();
        
      
        }
}

  render() {
    const from = { pathname: "/dashboard" };
    const { screen, redirectToReferrer } = this.state;
    const { logoDark, icon, closeLogin } = this.props;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <div>
        <div style={{  display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: 'top' }}>
        <div><img height={38} src={icon} />&nbsp;&nbsp;<img  style={{ marginTop:'14px'}} height={25} src={logoDark} /></div>
            <span key="back" style={{  cursor: "pointer" }}  onClick={closeLogin}>
                <h3>X</h3>
            </span>
        </div>
        <Divider style={{ marginTop: "15px", marginBottom: "15px", border: '1px solid orange'}}/>

        { (screen === 1) &&
        <div>
          <div>
            <FormattedMessage id="forms.email">
            { msg => <Input
              ref={this.myRef}
              autoFocus
              type="email"
              value={this.state.email}
              size="large"
              placeholder={msg}
              disabled={this.state.buttdis}
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              onChange={event => this.setState({email: event.target.value.toLowerCase()})}
              onPressEnter={event => this.handleLogin('password')}
              />}
            </FormattedMessage>
          </div>
          <div style={{ marginTop: "5px" }}>
            <FormattedMessage id="forms.password">
            { msg => <Input.Password
              type="password"
              value={this.state.password}
              size="large" 
              placeholder={msg}
              disabled={this.state.buttdis}
              prefix={<UnlockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              onChange={event => this.setState({password: event.target.value})}
              onPressEnter={event => this.handleLogin('password')}
              />}
            </FormattedMessage>
          </div>
          <div style={{ marginTop: "15px" }}>
            <Button
              size="large"
              type="primary"
              onClick={event => this.handleLogin('password')}
              loading={this.state.buttvalpw} 
              disabled={this.state.buttdispw} 
              block>
              <IntlMessages id="page.signInButton" />
            </Button>
          </div>
         


            <Divider style={{ marginTop: "15px", marginBottom: "5px" }}/>
          <div >
            <label>
              <IntlMessages id="page.signInCreateAccount" />&nbsp;
            </label>
            <label style={{ cursor: "pointer", color: "#4390ff" }} onClick={this.triggerSignup}>
              <IntlMessages id="page.signInCreateAccountLink" />
            </label>
          </div>
          <div style={{ marginTop: "5px" }}>
            <label>
              <IntlMessages id="page.signInForgotPass" />&nbsp;
            </label>
            <label style={{ cursor: "pointer", color: "#4390ff" }} onClick={this.triggerForgotPassword}>
              <IntlMessages id="page.signInForgotPassLink" />
            </label>
          </div>
        </div>
        }
        { (screen === 2) &&
        <div>
          <div style={{  display: "flex", justifyContent: "space-around", alignItems: 'center' }}>
            <QRCode value={this.state.totpUri} quietZone={20} size={200}/>
          </div>
          <div style={{  display: "flex", justifyContent: "space-around", alignItems: 'center', paddingTop: '10px' }}>
            <p style={{  margin: '0px', padding: '0px' }}>{this.state.secret}</p>
          </div>
          <Divider></Divider>
          <div>
           <IntlMessages id="page.signInScreen2.enterCode" />  
          </div>
          <div style={{  display: "flex", justifyContent: "space-around", alignItems: 'center', paddingTop: '10px' }}>
            <div>
              <Input 
              ref={this.myRef}
              autoFocus
              type="text" 
              size="large" 
              
              maxLength={6}
              value={this.state.verificationCode}
              style={{ width: '100px' }}
              onChange={event => this.finalizeVerification(event.target.value)}
              disabled={this.state.buttdis}
              />
            </div>
          </div>
        </div>  
        }
        { (screen === 3) &&
        <div>
          <div>
          <IntlMessages id="page.signInScreen3.enterCode" />  
          </div>
          <div style={{  display: "flex", justifyContent: "space-around", alignItems: 'center', paddingTop: '10px' }}>
            <div>
              <Input 
              ref={this.myRef}
              autoFocus
              type="text" 
              size="large" 
              
              maxLength={6}
              value={this.state.otpFromAuthenticator}
              style={{ width: '100px' }}
              onChange={event => this.runVerification(event.target.value)}
              disabled={this.state.buttdis}
              /> 
            </div>
            
          </div>
          <Divider style={{ marginTop: "15px", marginBottom: "5px" }}/>
            <div >
              <label>
                <IntlMessages id="page.signInReset2FA" />&nbsp;
              </label>
              <label style={{ cursor: "pointer", color: "#4390ff" }} onClick={() => this.triggerReset2FA()}>
                <IntlMessages id="page.signInLinkReset2FA" />
              </label>
            </div>
        </div>  
        }
        { (screen === 4) &&
        <div>
          <div>
            <IntlMessages id="not.useYourRecoveryPin" />:  
          </div>
          <div style={{  display: "flex", flexDirection: 'row', justifyContent: "space-around", alignItems: 'center', paddingTop: '10px' }}>
            <div>
              <Input 
                ref={this.myRef}
                autoFocus
                type="text" 
                size="large" 
                
                value={this.state.recoveryPin}
                
                onChange={event => this.setState({recoveryPin: event.target.value})}
                disabled={this.state.buttdis}
                />
            </div>
          
            <div style={{  display: "flex", flexDirection: 'row', justifyContent: "space-around", alignItems: 'center'}}>
            &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
              type='primary'
              size="middle"
              onClick={() => this.runReset2FA()}
              disabled={this.state.buttdis}
              >
                <IntlMessages id="signIn.continue" />
              </Button>&nbsp;
              <Button
              type='default'
              size="middle"
              onClick={() => this.setScreen3()}
              disabled={this.state.buttdis}
              >
                <IntlMessages id="signIn.chancel" />
              </Button>
            </div>
          </div>
        </div>  
        }
      </div>
    );
  }
}
/*
 &nbsp;&nbsp;&nbsp;
              <Button
              type='custom'
              size="small"
              onClick={event => this.killShowScreen()}
              disabled={this.state.buttdis}
              >
                killswitch
              </Button>

 <div style={{ marginTop: "15px" }}>
            <Button
   
              size="large"
              onClick={event => this.handleLogin('google')}
              loading={this.state.buttvalgoogle} 
              disabled={this.state.buttdisgoogle} 
              block>
              <Avatar size={25} src={SocialMediaIcons.google} />&nbsp;&nbsp;<IntlMessages id="page.signInButtonGoogle" />
            </Button>
          </div>
*/
export default injectIntl(connect(
  state => ({
    ...state.Auth,
    locale: state.LanguageSwitcher.language.locale,
    prodcount: state.Ecommerce.productQuantity.length,
  }),
  { login , logout, addUserCredentials, changeUserAddress }
)(SignIn));
