import planActions from './actions';

const initState = {
  planId: '',
};

export default function planReducer(state = initState, action) {

  switch (action.type) {
    case planActions.CHANGE_PLAN_ID:
      return {
        ...state,
        planId: action.recId
      };
    default:
      return state;
  }
}
