import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input, Checkbox, Divider, Row, Col, Avatar, Button, Select } from 'antd';
import { MailOutlined, GoogleOutlined, AppleOutlined } from '@ant-design/icons';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import FirebaseHelper from "../../helpers/firebase/index";
import notification from '../../components/uielements/notification';
import notificationIntl from '../../components/uielements/notificationIntl';
import { FormattedMessage,  injectIntl } from 'react-intl';
import IntlMessages from '../../components/utility/intlMessages';
import Countries from '../../settings/countries/forms';
const { logout } = authAction;
const { clearMenu } = appActions;  
const { Option } = Select;

class SignUp extends Component {
  constructor(props){
    super(props);
    this.triggerSignin = this.triggerSignin.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.singleOption = this.singleOption.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.acceptTC = this.acceptTC.bind(this);
    this.myRef = React.createRef();
    //console.log('ipcountry', this.props.ipCountry);

    this.state = {
      buttdis: false,
      username:'',
      email:'',
      password:'',
      password2:'',
      recoveryPin: '',
      recoveryPin2: '',
      checkbox: false
      
     }
    }

  componentDidMount() {
   const update = () => {
     this.myRef.current.focus();
   };
   setTimeout(update, 500);
  }  


  handleRegister(provider) {
  let res;
      //const { closeSignup } = this.props;
      const { formatMessage } = this.props.intl;
      const  { checkbox , username , email , password , password2, recoveryPin, recoveryPin2, showPassword } = this.state;

      if ( checkbox === false ) {
        //notification('error', 'You have to accept the terms and conditions.');
        notificationIntl('error','not.termsAccept',formatMessage);
        return;
        }
      if (!(username)) {
        //notification('error', 'Please fill out all required fields.');
        notificationIntl('error','not.fillAllFields',formatMessage);
        return;
        }
    if (provider == 'password') {
      
      if (!email) {
        notificationIntl('error','not.emailAndPassword',formatMessage);
        return;
      }
      function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
       }
      if (validateEmail(email) == false) {
        //notification('error', 'Please provide a valid email address.');
        notificationIntl('error','not.validEmail',formatMessage);
        return;
      }
      if (!(password && password2)) {
        //notification('error', 'Please define a password.');
        notificationIntl('error','not.confirmPassword',formatMessage);
        return;
      }
      if (password != password2) {
        //notification('error', 'Passwords are different.');
        notificationIntl('error','not.unequalPassword',formatMessage);
        return;
      }
      if (recoveryPin.length < 4) {
        //notification('error', 'Please define a password.');
        notificationIntl('error','not.min4digitRecoveryPin',formatMessage);
        return;
      }
      if (!(recoveryPin && recoveryPin2)) {
        //notification('error', 'Please define a password.');
        notificationIntl('error','not.confirmRecoveryPin',formatMessage);
        return;
      }
      if (recoveryPin != recoveryPin2) {
        //notification('error', 'Passwords are different.');
        notificationIntl('error','not.unequalRecoveryPin',formatMessage);
        return;
      }
    } else { this.setState({ showPassword: false});}

    this.setState({ buttdis: true });
    (async() => {

        const { checkbox , username, email , password, recoveryPin, buttdis } = this.state;
        const { locale } = this.props;
              const capitalize = (s) => {
                if (typeof s !== 'string') return ''
                return s.charAt(0).toUpperCase() + s.slice(1)
              }

              const member= {};
              member.username = capitalize(username);
              member.email = email;
              member.pin2FA = recoveryPin;
              member.pm = [];
              member.created_at = new Date().getTime();
              let c = ['en'];
              if (locale != 'en') {
                c = [ locale ,'en'];
              }
              const a = member.username;
              const b = {
               "email": member.email,
               "name": a,
               "preferred_locales": c,
               };
        if (provider == 'password') {
            res = await FirebaseHelper.firebaseCreateUser(email,password)
                .then(result => {
                  result.user.updateProfile({ displayName: a });
                  //if (result) {
                    const uid = result.user.uid;
                    const c = { "member": member, "a": b, "uid": uid, "type": "password" };
                  //  console.log('uid',uid,'payload',c);
                    return { payload: c };
                //  }
                })
                .catch(error => {
                  const message = error.message
                  notification('error', message);
                  this.setState({ buttdis: false });
                  return;
                });
        }
 
        if (res) {

            FirebaseHelper.httpCallable('HttpCallableCreateMember',res)
            
            .then(async(result) => {
              //notificationIntl('info','action.signUpOkSignInAgain',formatMessage);
              //this.props.logout();
              const { locale } = this.props;
              const userCred = await FirebaseHelper.firebaseLogin(email,password,locale)
                          .then(result => {

                            if (result) {
                              
                              if (result.user.emailVerified == false) {
                                FirebaseHelper.sendEmailVerification(this.props.locale)
                                .then(() => {
                                  this.props.logout();
                                  this.props.closeSignup();
                                  notificationIntl('info','action.sendEmailVerification',formatMessage);
                                  this.setState({ buttdis: false });

                
                                }).catch((error) => {
                                  this.props.logout();
                                  this.props.closeSignup();
                                  notificationIntl('info','action.sendEmailVerification',formatMessage);
                                  this.setState({ buttdis: false });
                                });
                
                              return;
                            } else {
                                      //   console.log('result',result);
                              return  result;
                                      
                              }
                            }
                          });




              
              
              this.setState({ buttdis: false });
              return;
              
            }).catch((error) => {
              var code = error.code;
              var message = error.message;
              notification('error', message);
              this.props.logout();
              this.props.closeSignup();
            });
        }
    })();

}

  triggerSignin() {
    this.props.handleLogin();
  }

  singleOption(valueObj,index) {
    return (<Option key={index} value={index }>{valueObj.name}</Option>);
    }

  setNumber(pin,value) {
    const { formatMessage } = this.props.intl;
    const { recoveryPin, recoveryPin2 } = this.state;
    if (Number.isInteger(Number(value))) {
      if (pin === 'recoveryPin') {
          this.setState({ recoveryPin: value});
        } else {
          this.setState({ recoveryPin2: value});
      }
    } else {
      notificationIntl('error','action.signUpPinIsNotNumber',formatMessage);
    }  
  }

  handleChange(value) {
    this.setState({ country: Countries[value].name });
    this.setState({ cphone: Countries[value].dial_code });
    this.setState({ countryFlag: Countries[value].flag });
    this.setState({ countryCode: Countries[value].code });
    }

    acceptTC() {
      this.props.showTC();
    }

  render() {
    const { history, logoDark, icon } = this.props;
    const { showPassword } = this.state;
 
    return (
         <div>
            <div>
            <div><img height={38} src={icon} />&nbsp;&nbsp;<img  style={{ marginTop:'14px'}} height={25} src={logoDark} /></div>
            </div>
            <Divider   style={{ marginTop: "15px", marginBottom: "15px", border: '1px solid orange' }}/>
            <Row gutter={16}>
              <Col style={{ marginBottom: "5px" }} xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormattedMessage id="forms.username">
                { msg => <Input ref={this.myRef} autoFocus type="text" size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.username} onChange={event => this.setState({username: event.target.value})}/>}
              </FormattedMessage>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormattedMessage id="forms.email">
                { msg => <Input type="email"  size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.email} onChange={event => this.setState({email: event.target.value.toLowerCase()})}/>}
                </FormattedMessage>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormattedMessage id="forms.password">
                { msg => <Input.Password  type="password"  size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.password} onChange={event => this.setState({password: event.target.value})}/>}
                </FormattedMessage>
              </Col>
              </Row>
            <Row gutter={16}>
              <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormattedMessage id="forms.passwordConfirm">
                { msg => <Input.Password type="password" size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.password2} onChange={event => this.setState({password2: event.target.value})}/>}
                </FormattedMessage>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormattedMessage id="forms.recover">
                { msg => <Input.Password  type="number"  size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.recoveryPin} onChange={event => this.setNumber('recoveryPin',event.target.value)}/>}
                </FormattedMessage>
              </Col>
              </Row>
            <Row gutter={16}>
              <Col style={{ marginTop: "5px" }} xs={24} sm={24} md={24} lg={24} xl={24}>
                <FormattedMessage id="forms.recoverConfirm">
                { msg => <Input.Password type="number" size="large" placeholder={msg} disabled={this.state.buttdis} value={this.state.recoveryPin2} onChange={event => this.setNumber('recoveryPin2',event.target.value)}/>}
                </FormattedMessage>
              </Col>
            </Row>
            
              <div  style={{ marginTop: "10px", width:'100%', display: "flex",  flexDirection: "row" ,alignItems: "start", justifyContent: "space-between" }}>
                <Checkbox checked={this.state.checkbox} disabled={this.state.buttdis} onChange={event => this.setState({checkbox: !this.state.checkbox})}></Checkbox>
                <div style={{ marginLeft: "5px" }}>
                  <label><IntlMessages id="page.signUpTC1" /></label><span style={{cursor:'pointer'}} onClick={this.acceptTC} ><a><IntlMessages id="page.signUpTC2" /></a></span><label><IntlMessages id="page.signUpTC3" /></label><span style={{cursor:'pointer'}} onClick={this.acceptTC} ><a><IntlMessages id="page.signUpTC4" /></a></span>.
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <Button
                  type="primary"
                  size="large"
                  onClick={event => this.handleRegister('password')}
                  loading={this.state.buttdis}
                  block>
                 <IntlMessages id="page.signUpButton" />
                </Button>
              </div>
              
              <div>
                <Divider style={{ marginTop: "15px", marginBottom: "5px" }}/>
                <label>
                  <IntlMessages id="page.signUpAlreadyAccount" />&nbsp;
                </label>
                <label style={{ cursor: "pointer", color: "#4390ff" }} onClick={this.triggerSignin}>
                  <IntlMessages id="page.signUpAlreadyAccountLink" />
                </label>
              </div>
            </div>
    );
  }
}
/*
<div style={{ marginTop: "15px" }}>
                <Button
                  size="large"
                  onClick={event => this.handleRegister('google')}
                  loading={this.state.buttdis}
                  block>
                  <Avatar size={25} src={SocialMediaIcons.google} />&nbsp;&nbsp;<IntlMessages id="page.signUpButtonGoogle" />
                </Button>
              </div>
*/
export default injectIntl(connect(
  state => ({
    locale: state.LanguageSwitcher.language.locale,
    ipCountry: state.Auth.country,
  }),
  { logout }
)(SignUp));
