export function getView(width) {
  let newView = "MobileView";
  if (width > 1260) {
    newView = "DesktopView";
  } 
  return newView;
}
const actions = {

  TOGGLE_ALL: "TOGGLE_ALL",
  toggleAll: (value) => {
    const view = getView(value.width);
   // console.log('width',value.width,'height',value.height);
    return {
      type: actions.TOGGLE_ALL,
      view,
      height: value.height,
      width: value.width
    };
  },

};
export default actions; 
