import actions from "./actions";
import { topbarTheme, sidebarTheme, layoutTheme} from '../../settings/index';


const initState = { isLoggedIn: false,
                    inputNumber: 2,
                    country: '',
                    currency: '',
                    region: '',
                    selectedType: 'All',
                    user: [],
                    userAddress: '',
                    topbarTheme: topbarTheme,
                    sidebarTheme: sidebarTheme,
                    layoutTheme: layoutTheme };

export default function authReducer(
  state = initState, action  )
  {switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      };

    case actions.ADD_USER_CREDENTIALS:
     return {
      ...state,
      user: state.user.concat([action.payload])
     }
    case actions.FETCH_USER:
      return {
       ...state,
      }
    case actions.SET_COUNTRY:
      return {
       ...state,
      country: action.country,
      currency: action.currency
      }
    case actions.CHANGE_COUNTRY:
      return {
       ...state,
      country: action.country,
      currency: action.currency
      }
    case actions.USER_ADDRESS:
      return {
       ...state,
      userAddress: action.userAddress
      }
    case actions.CHANGE_SELECTEDTYPE:
      return {
       ...state,
      selectedType: action.selectedType
      }
    
    case actions.LOGOUT:
      return {
       ...state,
      isLoggedIn: false,
      profilePicture: '',
      user: [],
      userAddress: ''
     }
    default:
      return state;
  }
}
