import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { ConfigProvider, Layout, Spin, Modal, Button, Tabs, Tooltip } from 'antd';
//import { SyncOutlined } from '@ant-design/icons';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import appActions from '../../redux/app/actions';
import TopbarShop from "../TopbarShop/topbarShop";
import TC from '../../components/terms/termsConditions';
import PP from '../../components/terms/privacyPolicy';
import { StripeProvider } from 'react-stripe-elements';
import gcp from '../../image/googleCloudPlatformLogo.svg';
import trustpilot from '../../image/trustpilot.png';
import stripe from '../../image/stripe_darkgrey.png';
import AppShopRouter from './AppShopRouter';
import MobileView from './mobileView';
import { siteConfig } from '../../settings';
import { stripeConfig } from '../../settings';
import AppLocale from "../../languageProvider";
import themes from '../../settings/themes';
import IntlMessages from "../../components/utility/intlMessages";
//import Affiliate from '../../containers/Page/addAffiliate';
const { TabPane } = Tabs;
const TopbarMember = lazy(() => import('../TopbarMember/topbarMember'));


const { Content, Footer } = Layout;
const { toggleAll } = appActions;

class AppShop extends Component {
  constructor(props) {
    super(props);
    this.showTC = this.showTC.bind(this);
    this.state = {
      stripe: null,
      tc: false,
      resize: Date.now(),
    };
  }
  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(stripeConfig.pk,{locale: 'en'})});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
      this.setState({stripe: window.Stripe(stripeConfig.pk,{locale: 'en'})});
      });
    }
    const handleResize = () => {
     // console.log('oki',Date.now() - this.state.resize);
      
      this.setState({ resize: Date.now() });
      this.props.toggleAll({
      width: window.innerWidth,
      height: window.innerHeight,
      });
    }
 
    function debounce(func, timeout = 200){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    }
    const debounceResize = debounce(() => handleResize());
    window.addEventListener("resize", debounceResize, false);
  }

  showTC() {
    this.setState({ tc: !this.state.tc});
  }

  mailToSupport(e) {
    window.location.href = "mailto:support@phraselocker.app";
    e.preventDefault();
  }

  render() {
    const { history , isLoggedIn, customizedTheme, locale, height, view, memberType } = this.props;
    const { url } = this.props.match;
    const { location } = this.props.location;
    const currentAppLocale = AppLocale[locale];
    const appHeight = window.innerHeight;
    let active = true;
    // old Background #f1f3f6
    //  <a href="https://www.trustpilot.com/review/phraselocker.app" target="_blank" rel="noopener"><img height={42} alt='trustpilot' src={trustpilot} style={{ marginRight: '20px'}}/></a>
                     
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >

          <ThemeProvider theme={themes.themedefault}>
            <StripeProvider stripe={this.state.stripe}>
              <Layout style={{ height: appHeight }}>
                
              {
                isLoggedIn? (
                <Suspense fallback={<div width='100%'  style={{ backgroundColor: customizedTheme.backgroundColor, height: 70 }}></div>}>
                  <TopbarMember url={url} view={view} history={history}/>
                </Suspense>
              ) : (

                  <TopbarShop showTC={this.showTC} url={url} view={view} history={history}/>

              )}
              <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                <Layout

                  style={{
                    height: height
                  }}
                >
                  <Content

                    style={{
                      padding: '60px 0 0',
                      flexShrink: '0',
                      backgroundColor: '#edeae2',
                      
                      position: 'relative'
                    }}
                  >
                    { view === "MobileView" ? (
                     <MobileView/>
                    ):( 
                    <AppShopRouter url={url} location={location} isLoggedIn={isLoggedIn} history={history} memberType={memberType} />
                    )}
                  </Content>
                  <Footer
                    style={view === "DesktopView" ? {
                      background: '#c5c1b7',
                      textAlign: 'center',
                      borderTop: '1px solid #d9d9d9',
                      fontSize: '12px',
                      height: '60px',
                      paddingTop: '3px',
                      paddingBottom: '0px'
                    }:{
                      background: '#c5c1b7',
                      textAlign: 'center',
                      borderTop: '1px solid #d9d9d9',
                      fontSize: '12px',
                      height: '60px',
                      paddingTop: '7px',
                      paddingBottom: '0px',
                      paddingLeft: '15px',
                      paddingRight: '10px'
                    }}
                  >
                  { view === "MobileView" ? (
                    <div style={{  height: '40px',display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "space-between" }}>
                      <div>
                        {siteConfig.footerText}
                      </div>
                      <div>
                        <img height={35} alt='GCP'  src={gcp} style={{ marginRight: '3px'}}/>
                      </div>
                    </div>
                  ):(
                    <div style={{ height: '50px',display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "space-between" }}>
                      <div>
                        {siteConfig.footerText}. All rights reserved.&nbsp;&nbsp;|&nbsp;&nbsp;<span style={{cursor:'pointer'}} onClick={this.showTC} >Terms & Privacy</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span style={{cursor:'pointer'}} onClick={(e) => this.mailToSupport(e)} >Support</span>
                      </div>
                      
                      <div>
                      <img height={24} alt='stripe' src={stripe} style={{ marginRight: '30px'}}/>
                      <Tooltip placement="top" title="Google Cloud Platform">
                        <img height={45} alt='GCP' src={gcp} style={{ marginRight: '3px'}}/>
                      </Tooltip>
                      
                      </div>
                    </div>
                 )}
                  </Footer>
                  <Modal

                    visible={this.state.tc}
                    closable={true}
                    onOk={this.showTC}
                    onClose={this.showTC}
                    onCancel={this.showTC}
                    footer={[
                      <Button type='primary' key="back" onClick={this.showTC}>
                        Ok
                      </Button>]}

                    width='800px'
                    zIndex='2000'
                    >
                    <div style={{  display: "flex",flexDirection: "column", justifyContent: "space-around", alignItems: 'center' }}>
                      <Tabs defaultActiveKey="1">
                        <TabPane tab="Terms and Conditions" key="1">
                          <TC/>
                        </TabPane>
                        <TabPane tab="Privacy Policy" key="2">
                          <PP/>
                        </TabPane>
                      </Tabs>
                    </div>
                  </Modal>
                  
              </Layout>
            </Layout>
          </Layout>
        </StripeProvider>
      </ThemeProvider>
      </IntlProvider>
      </ConfigProvider>


//   <img height={35} alt='stripe' width="auto" src={stripe} style={{border: '1px solid #d8d8d8' , borderRadius: '3px', marginRight: '3px'}}/>


    );
  }
}



export default connect(

  state => ({
        ...state.Auth,
        auth: state.Auth,
        locale: state.LanguageSwitcher.language.locale,
        isLoggedIn: state.Auth.isLoggedIn,
        customizedTheme: state.Auth.topbarTheme,
        height: state.App.height,
        view: state.App.view,
        memberType: state.Auth.userAddress.type || '',
  }),
  { toggleAll }
)(AppShop);
