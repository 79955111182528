import React, { Component } from 'react';
import LanguageSwitcher from '../../containers/LanguageSwitcher';
import { Menu } from 'antd';
import IntlMessages from '../../components/utility/intlMessages';
//const { SubMenu } = Menu;


export default class extends Component {
  render() {
   
    return (
        <div style={{ margin: '10px', marginTop: '30px' ,padding:'20px', paddingBottom: '20px', backgroundColor:'#ffffff', border: '1px solid #d8d8d8' , borderRadius: '6px'}}>
        <h2><IntlMessages id="mobileView.label1" /></h2>
        <span style={{ fontSize: '16px'}}><IntlMessages id="mobileView.label2" /></span><br/><br/>
        <span style={{ fontSize: '16px'}}><IntlMessages id="mobileView.label15" /></span><br/>
        <span style={{ fontSize: '16px'}}><IntlMessages id="mobileView.label16" /></span><br/><br/>
        <span style={{ fontSize: '16px'}}><IntlMessages id="mobileView.label3" /></span>&nbsp;
        <span style={{ fontSize: '16px'}}><IntlMessages id="mobileView.label4" /></span><br/><br/>
        <span style={{ fontSize: '16px'}}><IntlMessages id="mobileView.label5" /></span><br/>
        <span style={{ fontSize: '16px'}}><IntlMessages id="mobileView.label6" /></span>
       </div>
    )
  }
}