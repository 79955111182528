
import Dayjs from 'dayjs';
let year = Dayjs().format('YYYY');

export default {
  apiUrl: 'https://phraselocker.app/api/'
};

const siteConfig = {
  siteName: 'PhraseLocker',
  footerText: '© ' + year + ' by Cloud Gate, LLC'
};

const stripeConfig = {
  pk: 'pk_live_51NoTCMA2myNA4odfNYAlcxGhWSFVEwoDiDHxQUF4RSY7xJpds3cwwV6vMrJ30bufIGj5jTATy5uMFVrItpe9ietf00WwgB7k6f'
};
//#4670a2, #0881d8
const topbarTheme = {
buttonColor: '#0881d8',
backgroundColor: '#a04732',
textColor: '#ffffff'
}

const sidebarTheme = {
buttonColor: '#2D3446',
backgroundColor: '#2D3446',
textColor: '#788195'
}

const layoutTheme = {
buttonColor: '#F1F3F6',
backgroundColor: '#F1F3F6',
textColor: '#979797'
}

const firebaseConfig = {
  apiKey: "AIzaSyC7dBvlVMQ2abt9HBc764ZKBREE7lA596A",
  authDomain: "phraselocker.firebaseapp.com",
  projectId: "phraselocker",
  storageBucket: "phraselocker.appspot.com",
  appId: "1:335954619920:web:01c8115e1ca6e2ee3e2495"
};

const firebaseFunctions = {
  location: "europe-west6",
  projectId: "phraselocker"
};

//const googleConfig = {
//  apiKey: 'AIzaSyDQ1qDLNzMd2SF4A4L69A5CjlIRTNvohsk' 
//};googleConfig

export {
  siteConfig,
  stripeConfig,
  topbarTheme,
  sidebarTheme,
  layoutTheme,
  firebaseConfig,
  firebaseFunctions
};
