import React, { Component } from "react";
import { connect } from "react-redux";
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Tooltip, Alert, Divider, Button, Popover, Badge, Typography, Input, Space, Collapse } from "antd";
import IntlMessages from "../../components/utility/intlMessages";
import { FormattedMessage, injectIntl } from 'react-intl';
//import notification from '../../components/uielements/notification';
import Scrollbar from "../../components/utility/customScrollBar";
import FirebaseHelper from "../../helpers/firebase/index";
import SingleCart from "../../components/cart/singleCartModal";
import ecommerceAction from "../../redux/ecommerce/actions";
import CurrencyLabels from "../../settings/currencies/labels";
import notificationIntl from '../../components/uielements/notificationIntl';
import clone from 'clone';
const { Title } = Typography;
const { Panel } = Collapse;
const {changeViewTopbarCart, changeProductQuantity, changeProductQuantityAffiliate} = ecommerceAction;
let totalPrice;
let disabled;
let currency;
let checkoutDisabled;
let checkoutDisabledLogin;

class TopbarAddtoCart extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.renderProducts = this.renderProducts.bind(this);
    this.changeQuantity = this.changeQuantity.bind(this);
    this.cancelQuantity = this.cancelQuantity.bind(this);
    this.checkForCheckout = this.checkForCheckout.bind(this);
    //this.checkAffiliate = this.checkAffiliate.bind(this);
    this.state = {
      loginForCheckout: false,
      disabled: false,
      discountCode: '',
      checkedDiscountCode: ''
    };

  }
  hide() {
    this.props.changeViewTopbarCart(false);
  }
  handleVisibleChange() {
    this.props.changeViewTopbarCart(!this.props.viewTopbarCart);
  }


  checkForCheckout() {
    const { url, isLoggedIn, history, showLogin } = this.props;
    
    if (!isLoggedIn) {
      this.props.changeViewTopbarCart(!this.props.viewTopbarCart);
      showLogin();
      /*
      this.setState({ loginForCheckout: true });
      const update = () => {
      this.setState({ loginForCheckout: false });
      };
      setTimeout(update, 10000);
      //showLogin();*/
      return
    } else {
       
    history.push('/checkout');
    this.props.changeViewTopbarCart(!this.props.viewTopbarCart); 
    return 

   }    
  }

  changeQuantity(objectID, quantity) {
    const { productQuantity } = this.props;
    const newProductQuantity = [];
    productQuantity.forEach(product => {
      if (product.objectID !== objectID) {
        newProductQuantity.push(product);
      } else {
        newProductQuantity.push({
          objectID,
          quantity
        });
      }
    });

    this.props.changeProductQuantity(newProductQuantity);
  }
  cancelQuantity(objectID) {
    const { productQuantity, products } = this.props;
    const newProductQuantity = [];
    let productsClone = clone(products);
            productsClone.affiliate = ''; 
    productQuantity.forEach(product => {
      if (product.objectID !== objectID) {
        newProductQuantity.push(product);
      }
    });
    this.props.changeProductQuantityAffiliate(newProductQuantity, productsClone);
    this.setState({ discountCode : '' });
  }
  renderProducts() {
    const { productQuantity, products } = this.props;
    if (productQuantity && productQuantity.length > 0) {
      if (productQuantity[0].objectID === 'transfer') {
        disabled = true;
      } else {
        disabled = false;
      }
    }


    checkoutDisabled = true;
    totalPrice = 0;
    if (!productQuantity || productQuantity.length === 0) {
      return (
        <div className="isoNoItemMsg">
          <span><IntlMessages id="cart.empty" /></span>
        </div>
      );
    }


    return productQuantity.map((product,index) => {
      if (products[product.objectID].feesAdd) {
        totalPrice += product.quantity * ( products[product.objectID].price + products[product.objectID].feePlatform );
      } else {
        totalPrice += product.quantity * products[product.objectID].price;
      }

      currency = products[product.objectID].currency;
      checkoutDisabled = false;
      return (
        <div key={product.objectID}>
        {
          index === 0 &&
          <div>
            <h3 style={{  marginLeft: "0px", marginTop: "0px"}}>
              {products[product.objectID].title}
            </h3>
            <Divider  style={{  marginTop: "10px", paddingTop: "0px", marginBottom: "0px" }}/>
          </div>
        }

        <SingleCart
          key={product.objectID}
          index={index}
          objectID={product.objectID}
          quant={product.quantity}
          changeQuantity={this.changeQuantity}
          cancelQuantity={this.cancelQuantity}
          disabled={disabled}
          {...products[product.objectID]}
        />
        </div>
      );
    });
  }

  checkAffiliate(discountCode) {
    const { productQuantity, products } = this.props;
    const { formatMessage } = this.props.intl;
  if (discountCode.length > 1) {

    if (productQuantity.length < 1) {
      notificationIntl('error','cart.empty',formatMessage,'bottomRight');
      return;
    }
  FirebaseHelper.firebaseGetDoc('discountCode', discountCode)
  .then(doc => {

      if (doc.exists && doc.id) {

          let result = doc.data();
          
          let key = productQuantity[0].objectID;
          let price = products[key].price;
          if (!products[key].affiliate) {
            let discount = result.percentage * price;
            
            let productsClone = clone(products);
            productsClone.affiliate = discountCode; 
            productsClone[key].affiliate = discountCode; 
            productsClone[key].discountAmount = discount;
            productsClone[key].origPrice = price;
            productsClone[key].price = price-productsClone[key].discountAmount;
            
            this.props.changeProductQuantityAffiliate(productQuantity,productsClone);
            this.setState({ checkedDiscountCode: discountCode });
            return;
         }
      } else {
        notificationIntl('error','not.wrongDiscountCode',formatMessage,'bottomRight');
        let key = productQuantity[0].objectID;
        let productsClone = clone(products);
            productsClone.affiliate = ''; 
            productsClone[key].affiliate = ''; 
            if (productsClone[key].discountAmount) {
            productsClone[key].price = productsClone[key].origPrice;
            }
            productsClone[key].discountAmount = '';
            this.props.changeProductQuantityAffiliate(productQuantity,productsClone);
        this.setState({ discountCode: ''});
        return;
        console.log('wrong discount code'); 
      }
    })
   }
  }

  render() {
    const {
      url,
      productQuantity,
      products,
      viewTopbarCart,
      customizedTheme,
      prodcount,
      view
    } = this.props;
    const { loginForCheckout } = this.state;
    let discountAmount = 'notavailable';
    if (productQuantity.length > 0) {
    let key = productQuantity[0].objectID;
      if (products[key].discountAmount) {
    discountAmount = products[key].discountAmount;
   }
  }
    const content = (
      <div style={{ width: '100%', minWidth: '360px', padding: '10px' }} >
        <div  style={{ width: '100%',paddingTop: "10px"}} >
          <div style={{ display: "flex",  flexDirection: "row" ,alignItems: "start"}}>
            
            <ShoppingCartOutlined style={{  marginTop: '0px', marginRight: '5px', color:  '#328ba0', fontSize: "36px" }}/><span style={{ fontSize: '24px', color: '#454249' }}><b><IntlMessages id="cart.title" /></b></span>
          </div>
        
          <Divider style={{ marginTop: "0px", border: '1px solid orange'}}/>
        </div>
        <div className="isoDropdownBody isoCartItemsWrapper">
          <Scrollbar style={{ height: 120 }}>{this.renderProducts()}</Scrollbar>
        </div>
       { discountAmount !== 'notavailable' &&
        <div style={{ width:'100%', padding:'15px', marginBottom: "10px", display: "flex",  alignItems: 'center', justifyContent: 'space-between'}}>
          <div>{this.state.checkedDiscountCode}</div>
          <div>- {discountAmount.toFixed(2)}</div>
        </div>
        }
        <div style={{ marginBottom: '20px' }}> 

        <Collapse >
          <Panel header={<IntlMessages id="forms.dicountCode" />} key="1">
            <Space.Compact style={{ width: '100%' }}>
            <FormattedMessage id="forms.dicountCode">
            { msg => <Input 
              placeholder={msg}
              value={this.state.discountCode}
              onChange={event => this.setState({discountCode: event.target.value})}
              onPressEnter={event => this.checkAffiliate(this.state.discountCode)}
              />}
              </FormattedMessage>
              <Button 
              type="secondary" 
              onClick={event => this.checkAffiliate(this.state.discountCode)}>
                Check
              </Button>
            </Space.Compact>
          </Panel>
        </Collapse> 

          
        </div>
        <div>
        
        
        </div>
            <div className="isoDropdownFooterLinks" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                
                  <Button
                    type="primary"
                    size="middle"
                    onClick={this.checkForCheckout}
                    disabled={checkoutDisabled}
                    loading={this.state.checkoutLoading}
                    >
                    <IntlMessages id="cart.butCheckout" />
                  </Button>
                
              <h3>
                <span>Total&nbsp;{CurrencyLabels[currency]}&nbsp;{totalPrice.toFixed(2)}</span>
              </h3>
            
          </div>
    {    loginForCheckout &&
        <div style={{  marginTop: "10px", width: "270px" }}>
          <Alert message={<IntlMessages id="cart.loginFirst" />} type="info" showIcon/>
        </div>
    }
  </div>
    );
    return (
      <div>
      { view == 'DesktopView' ? (
      <FormattedMessage id="cart.title">
      { msg => <Tooltip placement="bottom" title={msg}>
      <Popover
        style={{ height: "200px" }}
        content={content}
        trigger="click"
        visible={viewTopbarCart}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomLeft"
      >
        <div>

          {prodcount === 0 ? (
            <ShoppingCartOutlined style={{ color: customizedTheme.textColor, fontSize: '35px' }} />
          ) : (

          <Badge size='small' count={prodcount} color='#328ba0'>
            <ShoppingCartOutlined style={{ color: customizedTheme.textColor, fontSize: '35px' }} />
          </Badge>

          )}
        </div>
      </Popover>
    </Tooltip>}
    </FormattedMessage>
    ):(
      <Popover
        content={content}
        trigger="click"
        visible={viewTopbarCart}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomLeft"
      >
        <div>

          {prodcount === 0 ? (
            <ShoppingCartOutlined style={{ color: customizedTheme.textColor, fontSize: '35px' }} />
          ) : (

          <Badge count={prodcount}>
            <ShoppingCartOutlined style={{ color: customizedTheme.textColor, fontSize: '35px' }} />
          </Badge>

          )}
        </div>
      </Popover>
    )}
    </div>
    );
  }
}

/*
<Link to={`${url}/checkout`} onClick={this.hide}>
  <IntlMessages id="topbar.viewCart" />
</Link>
*/

function mapStateToProps(state) {
  return {
    ...state.Ecommerce,
    customizedTheme: state.Auth.topbarTheme,
    isLoggedIn: state.Auth.isLoggedIn,
    prodcount: state.Ecommerce.productQuantity.length,
    productQuantity: state.Ecommerce.productQuantity,
    products: state.Ecommerce.products,
    view: state.App.view
  };
}
export default injectIntl(connect(mapStateToProps, {
  changeViewTopbarCart,
  changeProductQuantity,
  changeProductQuantityAffiliate
})(TopbarAddtoCart));
