import React, { Component } from 'react';
//import notification from '../../components/uielements/notification';

import { Button } from "antd";
import {
  DeleteOutlined, CaretUpOutlined, CaretDownOutlined
} from '@ant-design/icons';
import CurrencyLabels from "../../settings/currencies/labels";

export default class extends Component {
  constructor(props) {
    super(props);
    this.onChangeUp = this.onChangeUp.bind(this);
    this.onChangeDown = this.onChangeDown.bind(this);


    this.state = {
      quantityChoosen: this.props.values.quantityDefault,

    };
  }


 onChangeUp() {
   const { quantityChoosen } = this.state;
   const { values, onChange } = this.props;
   if (quantityChoosen < values.quantityMax ) {
   this.setState({ quantityChoosen: quantityChoosen + 1 });
   onChange(quantityChoosen + 1, values.id);
   }
 }
 onChangeDown() {
   const { quantityChoosen } = this.state;
   const { values, onChange} = this.props;
   if (quantityChoosen > values.quantityMin ) {
   this.setState({ quantityChoosen: quantityChoosen - 1 });
   onChange(quantityChoosen - 1, values.id);
   }
 }


  render() {
    const {
      values,
      disabled
    } = this.props;
    const {
      quantityChoosen
    } = this.state;

    return (
<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center'}}>
 <Button size='middle' disabled={disabled}>
  {quantityChoosen}
 </Button>
 <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center'}}>
 <Button size="small" disabled={true} onClick={this.onChangeUp}>
 <CaretUpOutlined />
 </Button>
 <Button size="small" disabled={true} onClick={this.onChangeDown}>
 <CaretDownOutlined />
 </Button>
 </div>
</div>

    );
  }
}
