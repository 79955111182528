
const planActions = {
  CHANGE_PLAN_ID: 'CHANGE_PLAN_ID',

  changePlanId: (recId) => ({
        type: planActions.CHANGE_PLAN_ID,
        recId
      }),

};
export default planActions;
