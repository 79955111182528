import FirebaseHelper from "../../helpers/firebase/index";
import Cookies from 'universal-cookie';

let countries = {
  AU:'AUD',
  CA:'CAD',
  CH:'CHF',
  LI:'CHF',
  DK:'DKK',
  GB:'GBP',
  GG:'GBP',
  JE:'GBP',
  IM:'GBP',
  NO:'NOK',
  NZ:'NZD',
  SE:'SEK',
  AX:'EUR',
  AD:'EUR',
  BE:'EUR',
  DE:'EUR',
  EE:'EUR',
  FI:'EUR',
  FR:'EUR',
  TF:'EUR',
  GF:'EUR',
  GR:'EUR',
  GP:'EUR',
  IE:'EUR',
  IT:'EUR',
  XK:'EUR',
  HR:'EUR',
  LV:'EUR',
  LT:'EUR',
  LU:'EUR',
  MT:'EUR',
  MQ:'EUR',
  YT:'EUR',
  MC:'EUR',
  ME:'EUR',
  NL:'EUR',
  AT:'EUR',
  PT:'EUR',
  RE:'EUR',
  BL:'EUR',
  MF:'EUR',
  SM:'EUR',
  SK:'EUR',
  SI:'EUR',
  ES:'EUR',
  PM:'EUR',
  VA:'EUR',
  EH:'EUR',
  CY:'EUR',
  TH:'THB',
  SG:'SGD',
  AE:'AED',
  JP:'JPY',
  KR:'KRW'
};

const cookies = new Cookies();

const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  ADD_USER_CREDENTIALS: 'ADD_USER_CREDENTIALS',
  FETCH_USER: 'FETCH_USER',
  CHANGE_COUNTRY: 'CHANGE_COUNTRY',
  SET_COUNTRY: 'SET_COUNTRY',
  CHANGE_SELECTEDTYPE: 'CHANGE_SELECTEDTYPE',
  USER_ADDRESS: 'USER_ADDRESS',

  login: () => {
    let data = null;
    return function(dispatch) {
      dispatch({
        type: actions.LOGIN_SUCCESS,
        payload: { data },
      });

    };
  },

  changeCountry: (country,currency) => ({
    type: actions.CHANGE_COUNTRY,
    country,
    currency
  }),

  changeUserAddress: (userAddress) => ({
    type: actions.USER_ADDRESS,
    userAddress
  }),

  setCountry: () => {
  //  console.log('country triggered');
    return async function(dispatch) {
      let a = await cookies.get('country');
      if (a) {
        let country = a;
        dispatch({
        type: actions.SET_COUNTRY,
        country
        });
      } else {
      const response = await fetch(`https://europe-west6-phraselocker.cloudfunctions.net/geolocationWebhook`)
        if (response.ok) {
         let result = await response.json();
         console.log('country', result.country,'ip', result.userIP,);
         let curr = 'USD';
         if (countries[result.country]) {
          curr = countries[result.country];
         }
         
          dispatch({
            type: actions.SET_COUNTRY,
            country: result.country,
            currency: curr
          });
        } else {
           console.log("HTTP-Error: ",response.status);
        }
      }
        
      }
  },

  changeSelectedType: (selectedType) => ({
    type: actions.CHANGE_SELECTEDTYPE,
    selectedType
  }),

  checkAuthorization: () => {
    return function(dispatch) {
    const userKey = Object.keys(sessionStorage)
    .filter(it => it.startsWith('firebase:authUser'))[0];
    const authuser = userKey ? JSON.parse(sessionStorage.getItem(userKey)) : undefined;
    dispatch(actions.setCountry());
    if (authuser) {
      console.log('Authorisation:','auto');
    //dispatch(push('/loading'));

    FirebaseHelper.firebaseGetDoc('members', authuser.uid)
    .then(doc => {
      if (doc.exists) {

         let user = doc.data();
         let uid = doc.id;

         if (user.accountId) { // is Affiliate
           
            let payload = user.accountId;
            FirebaseHelper.httpCallable('HttpCallableCheckStripeAccount',payload)
            .then((result) => {
              
              dispatch(actions.changeUserAddress(user));
              dispatch(actions.addUserCredentials('member'));                  //0
              dispatch(actions.addUserCredentials(user.username));             //1
              dispatch(actions.addUserCredentials('member'));                  //2
              dispatch(actions.addUserCredentials(user.email));                //3
              dispatch(actions.addUserCredentials('member'));                  //4
              dispatch(actions.addUserCredentials(uid));                       //5
              dispatch(actions.addUserCredentials(result.data.payouts_enabled));    //6
              dispatch(actions.addUserCredentials(result.data.link || ''));         //7
              dispatch(actions.login());
              //return;
            })
            .catch((error) => {
              console.log(error.code);
              console.log(error.message);
              return;
            });
          } else {           // is Member
              
              dispatch(actions.changeUserAddress(user));
              dispatch(actions.addUserCredentials('member'));         //0
              dispatch(actions.addUserCredentials(user.username));    //1
              dispatch(actions.addUserCredentials('member'));         //2
              dispatch(actions.addUserCredentials(user.email));       //3
              dispatch(actions.addUserCredentials('member'));         //4
              dispatch(actions.addUserCredentials(uid));              //5
              dispatch(actions.addUserCredentials(''));               //6
              dispatch(actions.addUserCredentials(''));               //7

              dispatch(actions.login());
              //return;

          }
      }
  });
      }
      else {

        console.log('Authorisation:','default');

        }
    }
  },

  logout: () => {
    return function(dispatch) {

      dispatch({ type: actions.LOGOUT });
      FirebaseHelper.logout();
    }
  },

  addUserCredentials: (value) => ({
    type: actions.ADD_USER_CREDENTIALS,
    payload: value //payload: { data },
  }),

  fetchUser: () => ({
    type: actions.FETCH_USER
  }),

};
export default actions;
