import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
//import ShopContent from "../ShopContent/shopContent";

const AuthRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props => isLoggedIn ? ( <Component {...props} />) : (<Redirect to={{
            pathname: "/",
            state: { from: props.location }
            
          }}
        />
      )
    }
  />
);
const AdminRoute = ({ component: Component, isLoggedIn, memberType, ...rest }) => (
  <Route
    {...rest}
    render={props => memberType === 'Admin' ? ( <Component {...props} />) : (<Redirect to={{
            pathname: "/",
            state: { from: props.location }
            
          }}
        />
      )
    }
  />
);


/*
<Route
  exact={true}
  key="shop"
  path={`${url}`}
  url={url}
  component={asyncComponent(() => import('../ShopContent/shopContent'))}
/>
*/

class AppShopRouter extends Component {


  render() {
    const { url, location, isLoggedIn, history, memberType} = this.props;
   
    return (
      <div>
        <Route
          exact={true}
          key="landing"
          path={`${url}`}
          url={url}
          location={location}
          component={asyncComponent(() => import('../Landing/landing'))}
        />
        <Route
          exact={true}
          key="404"
          path={`${url}/404`}
          component={asyncComponent(() => import('../Page/404'))}
        />
        <Route
          exact={true}
          key="action"
          path={`${url}/action`}
          location={location}
          component={asyncComponent(() => import('../Page/action'))}
          history={history}
        />
        <AdminRoute
          exact={true}
          key="productKey"
          path={`${url}/productKey`}
          location={location}
          component={asyncComponent(() => import('../Page/productKey'))}
          memberType={memberType}
          history={history}
        />
        <AuthRoute
          exact={true}
          key="checkout"
          path={`${url}/checkout`}
          component={asyncComponent(() => import('../Ecommerce/checkout/checkout'))}
          isLoggedIn={isLoggedIn}
          history={history}
        />
      </div>
    );
  }
}

export default AppShopRouter
 //connect nicht erlaubt!!!!
