import React from "react";
import { Route , Redirect } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";
import AppShop from "./containers/AppShop/AppShop";


const PublicRoutes = ({ history }) => {

  return (

    <ConnectedRouter history={ history } >
      <div>
        <Route
          path=""
          component={AppShop}
        />
      </div>
    </ConnectedRouter>

  );
};

export default connect(state => ({
}))(PublicRoutes);

/*
const PublicRoutes = ({ history, isLoggedInMember }) => {

  return (

    <ConnectedRouter history={ history } >
      <div>
        <Route
          path=""
          component={AppShop}
          isLoggedInMember={isLoggedInMember}
        />
      </div>
    </ConnectedRouter>

  );
};

export default connect(state => ({
  isLoggedInMember: state.Auth.isLoggedInMember !== false,
}))(PublicRoutes);







*/