


const labels = {
                  AUD: 'AU$',
                  CAD: 'CDN$',
                  CHF: 'CHF',
                  DKK: 'DKK',
                  EUR: '€',
                  GBP: '£',
                  NOK: 'NOK',
                  NZD: 'NZ$',
                  SEK: 'SEK',
                  USD: '$',
                  THB: 'THB',
                  SGD: 'SGD',
                  AED: 'AED',
                  JPY: 'JPY',
                  KRW: 'KRW'
                }; 

export default labels;
