import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

import { firebaseConfig } from '../../settings';
import { firebaseFunctions } from '../../settings';

export const fbConfig = firebase;

var app = firebase.initializeApp(firebaseConfig);


app.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

class FirebaseHelper {

  constructor() {
    this.database = app.firestore();
    this.auth = app.auth();
    this.functions = app.functions(firebaseFunctions.location);// -> eur-west6
    this.storage = app.storage();
  }

  firebaseStorageRef() {
    return this.storage.ref();
  }

  firestore() {
    return this.database;
  }

  auth() {
    return this.auth;
  }

  enrolledFactors() {
    let a = this.auth.currentUser;
    console.log('currentUser',a);
    return this.auth.currentUser.multiFactor.enrolledFactors;
  }

  emailAuthProvider(email,password) {
    return this.auth.EmailAuthProvider.credential(email,password);
  }


  applyActionCode(actionCode) {
    return this.auth.applyActionCode(actionCode);
  }

  checkActionCode(actionCode) {
    return this.auth.checkActionCode(actionCode);
  }

  verifyPasswordResetCode(actionCode) {
    return this.auth.verifyPasswordResetCode(actionCode);
  }

  confirmPasswordReset(actionCode, newPassword) {
    return this.auth.confirmPasswordReset(actionCode, newPassword);
  }

  sendPasswordResetEmail(email,locale) {
  //this.auth.useDeviceLanguage();
  this.auth.languageCode = locale;
  return this.auth.sendPasswordResetEmail(email);
  }

  updateEmail(email,locale){
  //this.auth.useDeviceLanguage();
  this.auth.languageCode = locale;
  return this.auth.currentUser.updateEmail(email);
  }

  verifyBeforeUpdateEmail(email,locale){
  this.auth.languageCode = locale;
  return this.auth.currentUser.verifyBeforeUpdateEmail(email);
  }
   


/*
recoverEmail(email,locale) {
//this.auth.useDeviceLanguage();
this.auth.languageCode = locale;
return this.auth.recoverEmail(email);
}
*/



  sendEmailVerification(locale) {
  //this.auth.useDeviceLanguage();
  this.auth.languageCode = locale;
  return this.auth.currentUser.sendEmailVerification();
  }

  httpCallable(name,data) {
    var functionRef = this.functions.httpsCallable(name);
    return functionRef(data);
  }

  createBatch = () => {
    return this.database.batch();
  };

  firebaseGetDocEmail(collect, email) {
    var userRef = this.database.collection(collect).where("email", "==", email);
    return userRef.get();
  }

  firebaseGetUsersCid(cid) {
    var userRef = this.database.collection('users').where('deleted_at', '==', null).where("cid", "==", cid).orderBy("lastname");
    return userRef.get();
  }

  firebaseGetUsersCidLimit1(cid) {
    var userRef = this.database.collection('users').where("cid", "==", cid).limit(1);
    return userRef.get();
  }

  firebaseGetDoc(collect, id) {
    var userRef = this.database.collection(collect).doc(id);
    return userRef.get();
  }

  firebaseInsertDoc(collect, id, data) {
    return  this.database.collection(collect).doc(id).set(data);
  }

  firebaseInsertDocSub(collect,id,subcollect,data) {
    return  this.database.collection(collect).doc(id).collection(subcollect).add(data);
  }

  firebaseInsertDocSubDoc(collect,id,subcollect,subid,data) {
    return  this.database.collection(collect).doc(id).collection(subcollect).doc(subid).set(data);
  }

  firebaseInsertDocRand(collect, data) {
    return  this.database.collection(collect).add(data);
  }

  firebaseUpdateDoc(collect, id, data) {
    this.database.collection(collect).doc(id).update(data);
  }

  firebaseUpdateDocAttribute(collect, id, attributeObj) {
    let b = {merge: true};
    var userRef = this.database.collection(collect).doc(id);
    return userRef.set(attributeObj,b);
  }
  
  firebaseArrayElementAdd(collect, id, array, data) {
    let a = {};
    a[array] = firebase.firestore.FieldValue.arrayUnion(data);
    var dbRef = this.database.collection(collect).doc(id);
    return dbRef.update(a);
  }

  firebaseDeleteDoc(collect, id) {
    var userRef = this.database.collection(collect).doc(id);
    return userRef.delete();
  }

  firebaseDeleteAttribute(collect, id, attribute) {
    let a = {};
    a[attribute] = firebase.firestore.FieldValue.delete();
    var delRef = this.database.collection(collect).doc(id);
    return delRef.update(a);
  }

  firebaseDeleteEntireDocSUB(collect,id,subcollect,subid) {
    return this.database.collection(collect).doc(id).collection(subcollect).doc(subid).delete();
  }

  firebaseDeleteEntireDoc(collect,id) {
    var userRef = this.database.collection(collect).doc(id)
    return userRef.delete();
  }

  firebaseDbDocRef(collection) {
    return this.database.collection(collection).doc();
  }

  firebaseCurrentUser() {
    return this.auth.currentUser;
  }

  googleAuthProvider() {
    return new firebase.auth.GoogleAuthProvider();
  }

 firebaseLogin(email,password) {
   return this.auth.signInWithEmailAndPassword(
    email,
    password
   );
 }

  firebaseLoginProvider(provider,locale) {
    this.auth.languageCode = locale;
    return this.auth.signInWithPopup(provider);
  }

  logout() {
    this.auth.signOut();
    console.log("Sign-out successful");
  }

  firebaseCreateUser(email,password) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

}

export default new FirebaseHelper();
